<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-button
        v-if="selectedView === 1"
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-button
        v-else
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="selectedView--"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-button>
      <ion-title class="text--white text-18 text--400">
        {{ $t('Create scene') }}
      </ion-title>
      <ion-button
        v-if="selectedView === 1"
        slot="end"
        debounce="500"
        :disabled="saving || !newScene.sceneName.length || !newScene.scenePicture.bg.length"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="createScene"
      >
        <ion-text
          class="text-16"
          color="primary"
        >
          {{ $t('save') }}
        </ion-text>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="text-left create-scene">
    <div
      v-if="selectedView === 1"
      class="ion-margin-top"
    >
      <div class="ion-padding-start ion-padding-end">
        <div v-if="!newScene.scenePicture.bg.length">
          <div>
            <ion-item
              lines="none"
              color="transparent"
              detail="false"
              class="select-picture no-ripple text--white"
              @click="selectedView = 2"
            >
              <i class="material-icons picture">image</i>
            </ion-item>
          </div>
          <div>
            <ion-item
              lines="none"
              color="transparent"
              class="select-picture text--white"
            >
              <ion-text class="select-picture-text text--gray text-16">
                {{ $t('selectImage') }}
              </ion-text>
            </ion-item>
          </div>
        </div>
        <div v-else>
          <ion-item
            color="transparent"
            lines="none"
            class="mt-10 rounded-corners h-100"
            :class="newScene.scenePicture.bg"
            @click="selectedView = 2"
          />
        </div>
        <form
          class="form--dark"
          :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
        >
          <ion-item
            lines="none"
          >
            <ion-label position="floating">
              {{ $t('Scene Name') }}
            </ion-label>
            <ion-input
              v-model="newScene.sceneName"
              class="capitalize"
              type="text"
              required
              enterkeyhint="done"
              @keydown.enter="closeKeyboard"
            />
          </ion-item>
        </form>
      </div>
      <ion-segment
        swipe-gesture="true"
        :value="String(selectedViewSub)"
        style="width: 300px"
        @ionChange="selectedViewSub = Number($event.target.value)"
      >
        <ion-segment-button value="1">
          <ion-label>
            <span class="text-16">{{ $t('devices') }}</span>
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="2">
          <ion-label>
            <span class="text-16">{{ $t('securityButton') }}</span>
          </ion-label>
        </ion-segment-button>
      </ion-segment>
      <div v-if="selectedViewSub === 1">
        <div v-if="newScene.sceneSettings.devices.length">
          <ion-text class="text--white ion-padding-start text--uppercase text-14">
            {{ $t('Control devices') }}
          </ion-text>
          <ion-grid>
            <ion-row>
              <ion-col
                v-for="device in newScene.sceneSettings.devices"
                :key="device"
                size="6"
              >
                <div
                  class="device rounded-corners text-center devices-room"
                >
                  <div>
                    <img
                      class="device-image"
                      :src="require('../../assets/devices/' + device.zigbeeModel + '.png')"
                    >
                  </div>
                  <div class="text--white text-16">
                    {{ device.deviceName }}
                  </div>
                  <div class="text-12 mt-10 text--gray">
                    {{ deviceRoom(device.propertyRoomId) }}
                  </div>
                  <div class="mt-15">
                    <ion-toggle
                      :checked="device.switchgear === 'on'"
                      :value="device.switchgear === 'on' ? 'off' : 'on'"
                      @ionChange="updateDevicesOnOffValueNewScenes($event.target.value,device.ieee)"
                    />
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div
          v-else
          class="ion-padding"
        >
          <ion-text class="text--white text-14">
            {{ $t('Control Devices') }}
          </ion-text>
          <ion-text class="text--gray text--lowercase mt-30">
            {{ $t('No devices to control found...') }}
          </ion-text>
        </div>
      </div>
      <div
        v-else-if="selectedViewSub === 2"
        class="ion-padding-start ion-padding-end"
      >
        <ion-item
          class="ion-no-padding"
          style="margin-top: -10px;"
          lines="none"
          color="transparent"
        >
          <ion-text
            slot="start"
            class="text--white text-14"
          >
            {{ $t('securityButton') }}
          </ion-text>
          <ion-text
            class="text--bold text-14"
            :class="newScene.alarm ? 'text--primary' : 'text--white'"
          >
            {{ newScene.alarm ? $t('ENABLED') : $t('DISABLED') }}
          </ion-text>
          <ion-toggle
            slot="end"
            v-model="newScene.alarm"
            @ionChange="addSecurity"
          />
        </ion-item>
        <div v-if="newScene.alarm">
          <span class="text--gray text-14">{{ $t('sceneAlarmInstructions') }} </span>
          <ion-list
            v-for="(group, propertyRoomId) in groupsSecurity"
            :key="propertyRoomId"
            class="text--white enabled-devices"
          >
            <!-- <div class="d-flex align-items-center justify-content-between">
              <ion-label class="text--gray text-14">
                {{ deviceRoom(propertyRoomId) }}
              </ion-label>
            </div> -->

            <div
              v-for="item in group"
              :key="item"
            >
              <ion-item
                class="text--white device-item"
                color="transparent"
                lines="none"
              >
                <img
                  slot="start"
                  style="width: 50px; margin-left: -20px;"
                  :src="require('../../assets/devices/' + item.zigbeeModel + '.png')"
                >
                <ion-label>
                  {{ item.deviceName }}<br>
                  <span class="text-12 text--gray">{{ deviceRoom(propertyRoomId) }}</span>
                </ion-label>
                <ion-toggle
                  slot="end"
                  v-model="item.enabled"
                  :value="item.enabled ? false : true"
                  @ionChange="enableSecurityDevice($event.target.value, item.ieee)"
                />
              </ion-item>
            </div>
          </ion-list>
        </div>
      </div>
    </div>
    <div
      v-else
      class="ion-padding"
    >
      <div
        v-for="{ id, bg } in backgrounds"
        :key="id"
      >
        <div
          style="height: 100px; border-radius: 10px; margin-top: 8px;"
          :class="bg"
          @click="newScene.scenePicture.bg = bg; selectedView = 1;"
        />
      </div>
      <div style="height: 20px;" />
    </div>
  </ion-content>
</template>

<script>
import {
  closeOutline,
  addCircleOutline,
  chevronBackOutline,
  addOutline
} from 'ionicons/icons'
import { defineComponent } from 'vue'
import {
  alertController,
  modalController,
  actionSheetController
} from '@ionic/vue'
import { Keyboard } from '@capacitor/keyboard'
import DetailDeviceModal from './DeviceDetailModal'

export default defineComponent({
  components: {},
  props: {
    devices: { type: Array, default: () => [] },
    close: { type: Function, default: null }
  },
  data () {
    return {
      closeOutline,
      addCircleOutline,
      chevronBackOutline,
      addOutline,
      backgrounds: [
        { id: 1, bg: 'scene-background-away' },
        { id: 3, bg: 'scene-background-home' },
        { id: 4, bg: 'scene-background-sleep' },
        { id: 5, bg: 'scene-background-sleep2' },
        { id: 6, bg: 'scene-background-morning2' },
        { id: 7, bg: 'scene-background-palms' },
        { id: 8, bg: 'scene-background-tvgame' },
        { id: 9, bg: 'scene-background-disco' },
        { id: 10, bg: 'scene-background-beach' },
        { id: 11, bg: 'scene-background-fireworks' },
        { id: 12, bg: 'scene-background-good-morning' },
        { id: 13, bg: 'scene-background-good-morning2' },
        { id: 14, bg: 'scene-background-morning-coffee' },
        { id: 15, bg: 'scene-background-morning-coffee2 ' },
        { id: 16, bg: 'scene-background-night-moon' },
        { id: 17, bg: 'scene-background-party' },
        { id: 18, bg: 'scene-background-pillows' },
        { id: 19, bg: 'scene-background-relax' },
        { id: 20, bg: 'scene-background-vacation2' },
        { id: 21, bg: 'scene-background-yoga' }
      ],
      selectedView: 1,
      selectedViewDetail: 1,
      selectedViewSub: 1,
      saving: false,
      newScene: {
        sceneName: '',
        scenePicture: {
          bg: ''
        },
        alarm: false,
        sceneSettings: {
          security: [],
          devices: []
        }
      }
    }
  },
  computed: {
    groups () {
      const key = 'propertyRoomId'
      const result = {}
      this.devices.forEach(item => {
        if (item.tunable) {
          if (!result[item[key]]) {
            result[item[key]] = []
          }
          result[item[key]].push(item)
        }
      })
      return result
    },
    groupsSecurity () {
      const key = 'propertyRoomId'
      const result = {}
      this.newScene.sceneSettings.security.forEach(item => {
        if (item.security) {
          if (!result[item[key]]) {
            result[item[key]] = []
          }
          result[item[key]].push(item)
        }
      })
      return result
    },
    platform () {
      return this.$store.state.platform
    }
  },
  watch: {
  },
  created () {
    this.newScene.sceneSettings.devices = this.$store.state.propertyDevices.filter(item => item.tunable === true)
  },
  methods: {
    async DetailDeviceModal (scene) {
      return modalController
        .create({
          component: DetailDeviceModal,
          cssClass: 'device-details',
          componentProps: {
            title: '',
            scene,
            componentProps: {
              close: () => this.closeModal()
            }
          }
        })
        .then(m => m.present())
    },
    async addMOdal () {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'add-automate-popup',
          buttons: [
            {
              text: 'Security',
              handler: () => {
                console.log('Delete clicked')
              }
            },
            {
              text: 'Devices',
              handler: () => {
                console.log('Share clicked')
              }
            },
            {
              text: 'Notifications',
              handler: () => {
                console.log('Play clicked')
              }
            }
          ]
        })
      await actionSheet.present()

      const { role } = await actionSheet.onDidDismiss()
      console.log('onDidDismiss resolved with role', role)
    },
    deviceRoom (id) {
      const roomId = Number(id)
      const rooms = this.$store.state.propertyRooms
      const found = rooms.find(element => element.id === roomId)
      return found ? found.roomName : '--'
    },
    mapName (zigbeeModel) {
      if (zigbeeModel === 'SensorMotion') {
        return 'Motion Sensor'
      } else if (zigbeeModel === 'SensorDoorWindow') {
        return 'Door & Window Sensor'
      } else if (zigbeeModel === 'SensorWaterLeak') {
        return 'Water Leak Sensor'
      } else if (zigbeeModel === 'SensorSmoke') {
        return 'Smoke Sensor'
      } else if (zigbeeModel === 'SmartPlug') {
        return 'Smart Plug'
      } else if (zigbeeModel === 'WallSwitch') {
        return 'Smart Wall Switch'
      } else if (zigbeeModel === 'SmartLightColorDimmable') {
        return 'Smart Bulb'
      }
    },
    deviceExists (ieee) {
      if (!this.newScene.sceneSettings.devices) {
        return null
      } else {
        const found = this.newScene.sceneSettings.devices.find(
          element => element.ieee === ieee
        )
        return !!found
      }
    },
    addAll (group) {
      group.forEach(device => {
        if (!this.newScene.sceneSettings.devices.includes(device)) {
          this.newScene.sceneSettings.devices.push(device)
        }
      })
    },
    addDeviceToNewScene (device) {
      this.newScene.sceneSettings.devices.push(device)
    },
    removeDeviceFromNewScene (device) {
      const deviceindex = this.newScene.sceneSettings.devices.findIndex(
        item => item.ieee === device.ieee
      )
      this.newScene.sceneSettings.devices.splice(deviceindex, 1)
    },
    updateDevicesOnOffValueNewScenes (value, ieee) {
      const deviceindex = this.newScene.sceneSettings.devices.findIndex(
        item => item.ieee === ieee
      )
      this.newScene.sceneSettings.devices[deviceindex].switchgear = value
      console.log(this.newScene.sceneSettings)
    },
    addSecurity () {
      if (this.newScene.alarm) {
        this.devices.forEach(item => {
          if (item.security) {
            const sceneSecurityDevice = {
              id: item.id,
              ieee: item.ieee,
              security: item.security,
              ep: item.ep,
              propertyRoomId: item.propertyRoomId,
              zigbeeModel: item.zigbeeModel,
              deviceName: item.deviceName,
              enabled: false
            }
            this.newScene.sceneSettings.security.push(sceneSecurityDevice)
          }
        })
      } else {
        this.newScene.sceneSettings.security = []
      }
    },
    enableSecurityDevice (value, device) {
      const securityDeviceIndex = this.newScene.sceneSettings.security.findIndex(item => item.ieee === device)
      if (value === 'true' && securityDeviceIndex !== -1) {
        this.newScene.sceneSettings.security[securityDeviceIndex].enabled = true
      } else if ((value === 'false' && securityDeviceIndex !== -1)) {
        this.newScene.sceneSettings.security[securityDeviceIndex].enabled = false
      } else {
        console.log('error')
      }
    },
    createScene () {
      if (this.saving) return

      this.saving = true
      this.newScene.sceneName = this.newScene.sceneName.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
      this.$store.dispatch('addPropertyScene', this.newScene).then(() => {
        this.dismissModal()
        this.saving = false
      })
    },
    dismissModal () {
      this.close()
    },
    async removeAlertConfirm (device) {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: 'Are you sure you want to remove this Device?',
          // message: 'Message <strong>text</strong>!!!',
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              }
            },
            {
              text: this.$t('Remove'),
              role: 'destructive',
              handler: () => {
                this.removeDeviceFromNewScene(device)
                console.log('Confirm Okay')
              }
            }
          ]
        })
      return alert.present()
    },
    closeKeyboard () {
      Keyboard.hide()
    }
  }
})
</script>

<style lang="scss" scoped>
@import "~@/theme/_colors";
@import "~@/theme/_backgrounds";
.remove-device {
  .alert-wrapper {
    .alert-title {
      font-size: 16px !important;
      letter-spacing: 0.5px !important;
    }
  }
}
.no-ripple {
     --ripple-color: transparent;
    --background-activated: transparent;
}

.device{
    height: 180px;
    @include bg-box-room-device;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.308);
  h3{
    margin: 0;
    margin-top:10px;
    font-size: 15px;
    letter-spacing: 0.6px;
    font-weight: normal;
  }
}
</style>
