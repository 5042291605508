<template>
  <div class="background--modal" />
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button
          slot="end"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="deviceDetailModalSheet"
        >
          <i class="material-icons">tune</i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen>
    <div class="device-details-content">
      <img src="../../assets/images/colored-bulb-large.png">
      <div class="text--white text-22 mt-10">
        KLLB3 Dim Bulb
      </div>
      <div class="text--gray text-16 mt-10">
        Living Room
      </div>
      <div class="mt-20">
        <ion-toggle />
      </div>
      <div class="brightness">
        <div class="text-14 text--white title">
          BRIGHTNESS
          <ion-text class="text-right text--gray">
            75%
          </ion-text>
        </div>
        <ion-item>
          <ion-range
            color="danger"
            pin="false"
          />
        </ion-item>
      </div>
      <div class="color">
        <div class="text-14 text--white text--uppercase title">
          Light temperature
        </div>
        <ion-item>
          <ion-range
            color="transparent"
            pin="false"
          />
        </ion-item>
      </div>
      <div class="mt-10 apply-btn">
        <ion-button>
          Apply
        </ion-button>
      </div>
      <div class="mt-10 apply-btn">
        <ion-text
          class="mt-20 text--primary"
          @click="closeModal()"
        >
          Cancel
        </ion-text>
      </div>
    </div>
  </ion-content>
</template>
<script>
import { defineComponent } from 'vue'
import { actionSheetController, modalController } from '@ionic/vue'
export default defineComponent({
  methods: {
    dismissModal () {
      this.close()
    },
    closeModal () {
      modalController.dismiss()
    },
    async deviceDetailModalSheet () {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'my-custom-class',
          buttons: [
            {
              text: 'Delete Device',
              role: 'destructive',
              handler: () => {
                console.log('Delete clicked')
              }
            },
            {
              text: 'Edit Device',
              handler: () => {
                console.log('Share clicked')
              }
            },
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked')
              }
            }
          ]
        })
      await actionSheet.present()

      const { role } = await actionSheet.onDidDismiss()
      console.log('onDidDismiss resolved with role', role)
    }
  }
})
</script>
<style lang="scss" scoped>
ion-header ion-toolbar:first-of-type{
padding-top:0;}
</style>